import { Link } from "gatsby"
import React from "react"
import Layout from "../../components/layout"

const ResourcesContainer = () => {
  return (
    <Layout>
      <div className="min-h-screen gradient">
        <div
          style={{ border: "1px solid #eaecee" }}
          className="w-3/5 mx-auto text-center pt-8 min-h-screen bg-white"
        >
          <h1>Collection of our thoughts</h1>
          <ul className="w-2/3 mx-auto">
            <li className="font-mono hover:shadow p-4 hover:bg-light-green underline">
              How to visualize data without any code
              <button className="font-sans bg-light-blue hover:shadow-xl ml-4 border-light-blue hover:text-black text-sm border-2 text-white  px-2 rounded">
                <Link to="/resources/how-to-visualize-data/">Read</Link>
              </button>
            </li>
            <li className="font-mono hover:shadow p-4 hover:bg-light-green underline">
              Marie Kondo Your Sales Strategy With Data Driven Marketing
              <button className="font-sans bg-light-blue hover:shadow-xl ml-4 border-light-blue hover:text-black text-sm border-2 text-white  px-2 rounded">
                <Link to="/resources/data-driven-sales-strategy/">Read</Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default ResourcesContainer
